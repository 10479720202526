import React from 'react';
import { Helmet } from 'react-helmet';
import GetEarlyAccess from '../components/home/GetEarlyAccess';

import metaImg from '../assets/images/rapidcontracts-metaimage.jpg';

import '../assets/stylesheets/application.scss';
import Footer from '../components/home/Footer';

function home() {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
        <title>Rapid Contracts</title>
        <meta name="image" content={metaImg} />
        <meta name="description" content="RapidContracts helps you create, negotiate and execute contracts collaboratively and pain-free." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={metaImg} />
        <meta property="og:title" content="Rapid Contracts" />
        <meta property="og:description" content="RapidContracts helps you create, negotiate and execute contracts collaboratively and pain-free." />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
      </Helmet>
      <div className="content-wrapper">
        <GetEarlyAccess />
        <Footer />
      </div>
    </>
  );
}

export default home;
