import React from 'react';
import logo from '../../assets/images/ventures.svg';

function Footer() {
  return (
    <div className="bg-white footer text-right">
      <div className="d-flex flex-row align-items-center justify-content-end h-100 fit-width">
        <small>Powered by </small>
        <a href="https://www.lawadvisor.ventures" target="_blank">
          <img className="mb-1 ml-1" height={18} src={logo} alt="" /></a>
      </div>
    </div>
  );
}

export default Footer;