
import React, { useEffect, useRef, useState } from 'react'
import Lottie from "lottie-react";
import animation from "./animation.json";
import isValidEmail from '../../utils/email';

import eagleLabs from '../../assets/images/eagle-labs.png';
import pexa from '../../assets/images/pexa.svg';
import birdBird from '../../assets/images/bird-bird.svg';
import lonelyPlanet from '../../assets/images/lonely-planet.svg';
import simmons from '../../assets/images/simmons-and-simmons.svg';
import logo from '../../assets/images/RapidContracts-logo.svg';
import TypeFormTag from '../TypeFormTag';
import { Link } from 'gatsby';

export default function GetEarlyAccess() {
  const lottieRef = useRef();

  const [notValidEmail, setNotValidEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [formUrl, setFormUrl] = useState('');
  const [headlineHeight, setHeight] = useState(null);

  useEffect(() => {
    const headline = document.getElementById("headlineContainer");
    setHeight(headline.offsetHeight);
  }, [])

  useEffect(() => {
    setFormUrl(`https://form.typeform.com/to/gq7YvpB4#emailaddress=${email}`);
    setNotValidEmail(false);
  }, [email])


  const submitApplicationAction = (e) => {
    if (!isValidEmail(email)) {
      e.preventDefault();
      setNotValidEmail(true);
    } else {
      setNotValidEmail(false);
    }
  }


  const handleSkip = () => {
    lottieRef.current.destroy();
    const bg = document.querySelector(".illo-bg");
    const container = document.querySelector(".headline-container");
    const companies = document.querySelector(".companies-container");
    const skipThis = document.querySelector(".skip-this");
    bg.classList.add('show');
    container.classList.add('show');
    companies.classList.add('show');
    skipThis.classList.add('show');
  }

  return (
    <div className="get-early-access" style={{ height: "100vh" }}>
      <div className="logo-container">
        <a href="/"><img src={logo} alt="logo" /></a>
      </div>
      <div className="headline-container" id="headlineContainer" style={{ marginTop: `calc(50vh - ${headlineHeight}px)` }}>
        <h1 className="headline mb-0">Close the deal.</h1>
        <h1 className="headline">Faster.</h1>
        <h3 className="subheading">RapidContracts helps you create, negotiate and execute contracts collaboratively and pain-free.</h3>
        <div className="action">
          <div className="field-container">
            <input className="field" type="email" placeholder="Enter your email address" onChange={e => setEmail(e.target.value)} />
            {isValidEmail(email) && <a className="typeform-share" onClick={submitApplicationAction} href={formUrl} data-mode="popup" target="_blank">
              <button className="button">Get early access</button>
            </a>}
            {!isValidEmail(email) && <button onClick={(e) => { e.preventDefault(); setNotValidEmail(true) }} className="button">Get early access</button>}
            {isValidEmail(email) && <TypeFormTag />}
          </div>
          {notValidEmail && <div className="text-danger">Please make sure to enter valid email</div>}
          <div className="mt-60 d-block d-md-none"><p>Trusted by</p></div>
          <div className="row-small first">
            <img className="co pexa" src={pexa} alt="pexa" />
            <img className="co planet" src={lonelyPlanet} alt="lonely-planet" />
            <img className="co simmons" src={simmons} alt="simmons-simmons" />
          </div>
          <div className="row-small last">
            <img className="co bird" src={birdBird} alt="bird-bird" />
            <img className="co eagle" src={eagleLabs} alt="eagle-labs" />
          </div>
        </div>
      </div>
      <div className="companies-container">
        <div className="d-none d-md-block"><p>Trusted by</p></div>
        <div className="row">
          <img className="co pexa" src={pexa} alt="pexa" />
          <img className="co planet" src={lonelyPlanet} alt="lonely-planet" />
          <img className="co simmons" src={simmons} alt="simmons-simmons" />
          <img className="co bird" src={birdBird} alt="bird-bird" />
          <img className="co eagle" src={eagleLabs} alt="eagle-labs" />
        </div>
      </div>
      <div className="skip-this">
        <div onClick={() => handleSkip()} className="label">Skip</div>
      </div>
      <div className="illo-bg"></div>
      <div id="lottie-container">
        <Lottie
          animationData={animation}
          loop={false}
          autoplay
          lottieRef={lottieRef}
        />
      </div>
    </div>
  );
}